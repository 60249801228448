<template>
  <lab-task
    ><lab-calorimetry
      :variation="variation"
      :c-cal="cCal"
      :temp-before-mixing="tempBeforeMixing"
      :c-metal="cMetal"
  /></lab-task>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {default as LabCalorimetry, Variation} from '@/tasks/components/simulations/LabCalorimetry';
import {defineComponent} from '@vue/composition-api';
import LabTask from './labs/LabTask';

export default defineComponent({
  name: 'CalorimetryCSIM',
  components: {LabCalorimetry, LabTask},
  mixins: [DynamicQuestionMixin()],
  data: () => ({
    variation: Variation.C,
  }),
  computed: {
    cCal() {
      return this.taskState.getValueBySymbol('cCal').content.toFloat();
    },
    tempBeforeMixing() {
      return this.taskState.getValueBySymbol('Tbm').content.toFloat();
    },
    cMetal() {
      return this.taskState.getValueBySymbol('cMetal').content.toFloat();
    },
  },
});
</script>
